<template>
	<div class="about-detail">
		<h2 id="intro01">{{detail.name}}</h2>
       <div v-html="detail.content"></div>
		<!-- <h2 id="intro03">技术介绍</h2>
       <p>增减材复合制造技术是将增材制造与车铣加工复合起来的加工技术，通过增材过程实现零件的近净成形，并通过切削加工保证加工精度和表面质量。相对于传统工艺，可以有效地提高材料利用率，减少切削余量并缩短加工周期。通过逐个特征增减材复合加工，可实现复杂形面及具有内孔、内腔、内流道零件的一体化高效制造。</p>
       <div class="img-box"><img :src="require('../../assets/about/4a02d11ebd1dabe9fffffa08fcf469d.png')" /></div>
       <p>激光熔化沉积（LMD）技术是激光金属增材制造技术的一种，通过金属材料的“激光熔化-快速凝固”逐层沉积，可实现金属零件高性能近净成形，适用于高精度复杂零件的高效增材制造和修复再制造等需求。</p>
       <div class="img-box"><img :src="require('../../assets/about/intro2-001.jpg')" /></div> -->
<!--        <div class="img-box"><img style="width:500px;margin-bottom:20px;" :src="require('../../assets/about/4a02d11ebd1dabe9fffffa08fcf469d.png')" /></div> -->

	</div>
</template>

<script>
import { ref,onMounted,watch,toRefs } from 'vue'
import {useRoute} from 'vue-router'

export default {
	name: '',
	components:{},
	props:{
    list:{type:Array,default:[]}
  },
  setup(props){
    const route = useRoute();
    const detail = ref({content:'', name:''});
    const { list } = toRefs(props);
    onMounted(()=>{
      if(route.path==='/about/ab3'&&list.value.length>0){ 
        detail.value = list.value[2]
      }
    })
    watch(list,()=>{
      detail.value = list.value[2]
    })
    return {detail}
  }
}
</script>

<style scoped type="text/css">
	#intro01{font-size:22px; color:#111; font-weight: bold; padding:5px 0; text-align:center; margin:10px 0 20px 0; border-bottom:1px solid #eee;}
</style>
